import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import { ElButton, ElSelect, ElOption, ElImage, ElAlert, ElMessageBox, ElUpload, ElIcon, ElInput, ElDivider, ElTooltip, ElSwitch, ElLoading } from 'element-plus';

// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/preview.css';
const app = createApp(App).use(router);
app.config.globalProperties.axios = axios;
app.use(ElSelect);
app.use(ElButton);
app.use(ElAlert);
app.use(ElOption);
app.use(ElImage);
app.use(ElMessageBox);
app.use(ElUpload);
app.use(ElIcon);
app.use(ElDivider);
app.use(ElTooltip);
app.use(ElSwitch);
app.use(ElInput);
app.use(ElLoading);

// app.use(ElementPlus)
app.use(MdPreview);
app.mount('#app');