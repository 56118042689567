import { createRouter, createWebHistory } from 'vue-router'
//import App from "../App"
//import { createRouter } from 'vue-router'


const routes = [
  // {
  //   path: '/',
  //   name: 'App',
  //   component: App
  // },


  {
    path: '/pages/mchat',
    name: 'chat',
    component: () => import('../components/GaliChat.vue')
  },
  {
    path: '/pages/history',
    name: 'history',
    component: () => import('../components/GaliHistory.vue')
  },
  {
    path: '/pages/wescanlogin',
    name: 'wescanlogin',
    component: () => import('../components/WeScanLogin.vue')
  },
  {
    path: '/pages/vip',
    name: 'vip',
    component: () => import('../components/VipManager')
  },
  {path: '/', redirect: '/pages/mchat'}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// const router = createRouter({
//   mode: "hash",
//   routes
// })

export default router
