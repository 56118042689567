<template>
  <div class="root">
    <!--    <div class="root-menu-bar-flex">-->
    <!--      <div class="root-menu-flex">-->
    <!--        <el-menu class="menu" router="true" :default-active="activeIndex" @select="handleSelect" mode="horizontal"-->
    <!--                 background-color="#909399" text-color="#fff" active-text-color="#ffd04b">-->
    <!--          <el-menu-item index="/pages/chat">galigulu.com</el-menu-item>-->
    <!--        </el-menu>-->
    <!--      </div>-->
    <!--      <div class="root-notice-flex">-->
    <!--        <div class="root-info-show-flex">-->
    <!--          推荐您添加书签，方便再次免费试用-->
    <!--        </div>-->

    <!--        <div class="root-info-show-flex">-->
    <!--          交流请加QQ群： 817647474-->
    <!--        </div>-->
    <!--        <div class="root-model-select-flex">-->
    <!--          <el-select v-model="value1" :placeholder="modelDisplay" @change="selectModel">-->
    <!--            <el-option-->
    <!--                v-for="item in options"-->
    <!--                :key="item.value"-->
    <!--                :label="item.label"-->
    <!--                :value="item.value">-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </div>-->
    <!--        <div class="root-notice-button-flex">-->
    <!--          <el-button type="info" icon="el-icon-warning-outline" @click="showCollectInfo"></el-button>-->

    <!--          &lt;!&ndash;            <div class="root-notice-image-contain">&ndash;&gt;-->
    <!--          &lt;!&ndash;              <el-image class="root-notice-image" :src="ImageHint" alt="推荐您添加书签，方便再次免费试用"></el-image>&ndash;&gt;-->
    <!--          &lt;!&ndash;              <div class="cover">&ndash;&gt;-->
    <!--          &lt;!&ndash;                推荐您添加书签，方便再次免费试用&ndash;&gt;-->
    <!--          &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--        </div>-->
    <!--        &lt;!&ndash;          <div class="root-notice-button-flex">&ndash;&gt;-->
    <!--        &lt;!&ndash;&lt;!&ndash;              <el-image class="root-notice-image" :src="ImageNotice" ></el-image>&ndash;&gt;&ndash;&gt;-->
    <!--        &lt;!&ndash;              <el-button type="info" icon="el-icon-star-off" @click="showInfo" ></el-button>&ndash;&gt;-->

    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="child">
      <router-view></router-view>
    </div>


  </div>
</template>


<script>

// import ImageNotice from "@/assets/bell.png";
// import ImageHint from "@/assets/hint.png";
//import Register from './components/Register.vue'
//import Mchat from './components/Mchat';
export default {
  name: 'App',
  components: {
    //Mchat,
  },


  mounted: function () {
    this.$router.push({path: "/pages/mchat"});
  },

  provide() {
    return {
      app: this
    };
  },
  // data() {
  //   return {
  //     activeIndex: '1',
  //     ImageNotice: ImageNotice,
  //     ImageHint: ImageHint,
  //     selectedValue: '',
  //     value1: [],
  //     // 下拉选项数据
  //     model:"gpt-4o-mini",
  //     modelDisplay: "gpt-4o-mini",
  //     options: [
  //       { label: 'gpt-4o-mini', value: 'gpt-4o-mini' },
  //       { label: 'gpt-4o', value: 'gpt-4o' },
  //       { label: 'o1-preview', value: 'o1-preview' },
  //       { label: 'o1-mini', value: 'o1-mini'}
  //     ]
  //   };
  // },


  methods: {
    // handleSelect(key) {
    //   console.log(key);
    // },
    //
    // selectModel(val) {
    //   console.log(val)
    //   if (val === "gpt-4o") {
    //     this.model = "gpt-4o"
    //   } else {
    //     this.model = val
    //   }
    // },
    //
    // showInfo() {
    //   //alert("交流请加QQ群： 817647474 ");
    // },
    //
    // showCollectInfo() {
    //
    //   window.open("https://maplebolo.feishu.cn/wiki/P90xw39gviKQ8JkfuTPcYgZBnrd", "_blank");
    // }
  }
}
</script>

<style>
#app {
  font-family: 'Microsoft YaHei',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}


.root-menu-bar-flex {
  flex: 0 0 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}


.root-menu-flex {
  background-color: #909399;
  order: 0;
  flex: 10 1 auto;
}

.root-notice-flex {
  order: 1;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #909399;
  border-bottom: solid 1px rgb(230 230 230);
}

.root-info-show-flex {
  background-color: #909399;
  order: 1;
  flex: 2 1 auto;
  color: white;
}

.root-model-select-flex {
  background-color: #909399;
  order: 2;
  flex: 2 1 auto;
}


.root-notice-button-flex {
  background-color: #909399;
  order: 3;
  flex: 1 1 auto;
}


.root-notice-star:hover .cover {
  display: block;
}


.child {
  width: 100%;
  flex: 1;
  /*background-image: url('assets/background.jpeg');*/
  /*background-size: cover;*/
}

.bg-part {
  display: flex;
  justify-content: center;
  padding: 25px;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 8px !important;
}

/*::-webkit-scrollbar {*/
/*  width: 0 !important;*/
/*  height: 0;*/
/*}*/

/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

</style>
